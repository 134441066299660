import Divider from '@/components/common/Divider';
import Modal, { ModalClose, ModalHeader, ModalTitle } from '@/components/modal/Modal';
import { NotificationIcon } from '@/components/modules/Notifications';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import {
    closeNotifications,
    openNotifications,
    selectNotifications,
    selectNotificationsOpened,
    selectNotificationsVisited,
    toggleNotifications
} from '@/state/reducers/notificationSlice';
import { selectGeneralSettings, selectNotificationSettings } from '@/state/reducers/settingSlice';
import { formatNotification, formatUTCNotification } from '@/utils/format';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { parseNotificationSettings } from '@/utils/hooks/useToast';
import cn from 'classnames';
import { useMemo } from 'react';

interface NotificationsModal {}

const DesktopNotificationsModal = (props: NotificationsModal) => {
    const dispatch = useAppDispatch();
    const notifications = useAppSelector(selectNotifications);
    const notificationsVisited = useAppSelector(selectNotificationsVisited);
    const notificationSettings = useAppSelector(selectNotificationSettings);
    const generalSettings = useAppSelector(selectGeneralSettings);
    const defaultConfig = parseNotificationSettings(notificationSettings);
    const opened = useAppSelector(selectNotificationsOpened);

    const handlers: any = useMemo(
        () => ({
            open: () => dispatch(openNotifications()),
            close: () => dispatch(closeNotifications()),
            toggle: () => dispatch(toggleNotifications())
        }),
        [opened]
    );

    useDidUpdate(() => {
        if (!notificationsVisited && notifications.length > 0 && defaultConfig.show) handlers.open();
    }, [notifications]);

    return (
        <Modal opened={opened} handlers={handlers} size="max-w-xl" closeOnOverlay>
            <ModalHeader>
                <ModalTitle>Notifications</ModalTitle>
                <ModalClose handlers={handlers} />
            </ModalHeader>
            <Divider />
            {notifications.length === 0 ? (
                <div className="flex h-[550px] items-center justify-center text-neutral-500">No notifications</div>
            ) : (
                <div className="h-[550px] overflow-y-scroll w-full bg-brand-background-dark text-neutral-200">
                    {notifications.map(({ title, body, type, created_at }, id) => {
                        return (
                            <div
                                key={id}
                                className={cn('flex gap-3 bg-brand-background-dark text-neutral-200 p-2 sm:px-4', {
                                    ['border-b border-neutral-700']: true
                                })}>
                                <span className="mt-1">
                                    <NotificationIcon type={type} />
                                </span>
                                <div className="flex flex-col w-full text-sm sm:text-base">
                                    <div className="flex w-full justify-between gap-2">
                                        <div className="font-semibold line-clamp-2">{title}</div>
                                        <div className="text-sm sm:text-base text-neutral-400 whitespace-nowrap flex flex-col items-end">
                                            <span>
                                                {generalSettings.timezone.value === 'UTC'
                                                    ? formatUTCNotification(created_at)
                                                    : formatNotification(created_at)}{' '}
                                                ( {generalSettings.timezone.label.replace(' Timezone', '')} )
                                            </span>
                                        </div>
                                    </div>
                                    <div className="text-neutral-400 whitespace-pre-wrap text-sm sm:text-base">
                                        {body}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </Modal>
    );
};

export default DesktopNotificationsModal;
