export enum DeviceAllowedStatusType {
    NOT_ALLOWED = 0,
    AWAITING = 1,
    ALLOWED = 2
}

export const checkiOSDevice = () => {
    const appleRegExp = new RegExp(String.raw`mac os`, 'i');
    return appleRegExp.test(navigator.userAgent) && (!!navigator.maxTouchPoints || 'ontouchend' in document);
};
