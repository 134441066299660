import instrumentConfig from '@/config/instruments';
import { PairMap } from '@/state/reducers/marketPairSlice';
import { useCurrency } from '@/utils/hooks/useCurrency';
import { useSso } from '@/utils/providers/SSOProvider';
import { useEffect, useMemo, useState } from 'react';
import Drawer from './Drawer';
import TabFilters from './TabFilters';

export const formConfig = {
    offsetOverride: { mainAxis: 2, crossAxis: -151 }
};

export enum InstrumentInfoContextTab {
    'General' = 0,
    'Trade Info' = 1
}

export const InstrumentInfoContextTabs: {
    label: keyof typeof InstrumentInfoContextTab;
    value: InstrumentInfoContextTab;
}[] = [
    { label: 'General', value: InstrumentInfoContextTab.General },
    { label: 'Trade Info', value: InstrumentInfoContextTab['Trade Info'] }
];

interface InstrumentInfoContextProps {
    market?: PairMap;
    handleCloseContextMenu: () => void;
}

const InstrumentInfoContext = ({ market, handleCloseContextMenu }: InstrumentInfoContextProps) => {
    const { jwt, instrumentsApi } = useSso();
    const { data: instruments } = instrumentsApi;

    const isXplorSpot = !location.pathname.includes('/trader');

    const [instrumentInfoContextTab, setInstrumentInfoContextTab] = useState(InstrumentInfoContextTab.General);
    const [ccy1, ccy2] = useMemo(() => market?.celer.split('/') || [], [market?.celer]);

    const ccy1Config = useCurrency(ccy1);
    const ccy2Config = useCurrency(ccy2);

    const xplorSpotConfig = useMemo(() => instrumentConfig[market?.celer || ''] || {}, [market]);
    const ohoConfig = useMemo(
        () =>
            instruments?.data?.find((instrument) => {
                if (isXplorSpot) {
                    if (['Index'].includes(xplorSpotConfig.type)) {
                        return instrument.name === `CFD/${market?.celer}`;
                    } else {
                        return instrument.name.replace('CFD/') === market?.celer;
                    }
                } else {
                    if (['Index', 'Crypto'].includes(xplorSpotConfig.type)) {
                        return instrument.name === `CFD/${market?.celer}`;
                    } else {
                        return instrument.name.replace('CFD/') === market?.celer;
                    }
                }
            }),
        [xplorSpotConfig, instruments, market]
    );

    const { isCFD, isRollingFiatFX } = useMemo(
        () => ({
            isCFD: ohoConfig?.type === 'CFD',
            isRollingFiatFX: ohoConfig?.type === 'FX' && ohoConfig?.subType === 'Spot',
            isCryptoSpot: ohoConfig?.type === 'Crypto' && ohoConfig?.subType === 'Spot'
        }),
        [ohoConfig]
    );

    useEffect(() => {
        if (!jwt || !ohoConfig) setInstrumentInfoContextTab(InstrumentInfoContextTab['Trade Info']);
        else setInstrumentInfoContextTab(InstrumentInfoContextTab.General);
    }, [jwt, ohoConfig]);

    return (
        <div className="flex flex-col gap-2 z-[100] cursor-auto text-neutral-200">
            <TabFilters.Main>
                {jwt && ohoConfig && (
                    <TabFilters.Filter
                        className="!text-xs h-[24px]"
                        selectedFilter={instrumentInfoContextTab}
                        setSelectedFilter={setInstrumentInfoContextTab}
                        filter={InstrumentInfoContextTabs[0]}
                    />
                )}
                <TabFilters.Filter
                    className="!text-xs h-[24px]"
                    selectedFilter={instrumentInfoContextTab}
                    setSelectedFilter={setInstrumentInfoContextTab}
                    filter={InstrumentInfoContextTabs[1]}
                />
            </TabFilters.Main>
            {jwt && ohoConfig && instrumentInfoContextTab === InstrumentInfoContextTab.General && (
                <div className="flex flex-wrap gap-3 p-2 pt-0 justify-between text-xs">
                    <Drawer.InfoSubGroup
                        labelClassName="whitespace-nowrap"
                        details={{
                            Symbol: { value: market?.show || '-' },
                            Type: { value: ohoConfig.type },
                            Underlying: { value: ohoConfig.underlying, hidden: !isCFD },
                            'Ccy 1': { value: ohoConfig.currency1, hidden: isCFD },
                            CFI: { value: ohoConfig.cfi || '-', hidden: !isCFD && !isRollingFiatFX }
                        }}
                    />
                    <Drawer.InfoSubGroup
                        labelClassName="whitespace-nowrap"
                        className="flex-[3_1_0]"
                        details={{
                            Description: { value: ohoConfig.description || '-' },
                            'Sub Type': { value: ohoConfig.subType },
                            Ccy: { value: ohoConfig.currency2, hidden: !isCFD },
                            'Ccy 2': { value: ohoConfig.currency2, hidden: isCFD },
                            UPI: { value: ohoConfig.upi || '-', hidden: !isCFD && !isRollingFiatFX },
                            'MiFID Scope': {
                                value: ohoConfig.miFIDscope ? 'Enabled' : 'Disabled',
                                hidden: !isCFD
                            }
                        }}
                    />
                </div>
            )}
            {instrumentInfoContextTab === InstrumentInfoContextTab['Trade Info'] && (
                <div className="flex flex-wrap gap-3 p-2 pt-0 justify-between text-xs">
                    <Drawer.InfoSubGroup
                        labelClassName="whitespace-nowrap"
                        details={{
                            Type: { value: xplorSpotConfig.type },
                            'Price Decimals': { value: xplorSpotConfig.price_decimals }
                        }}
                    />
                    <Drawer.InfoSubGroup
                        labelClassName="whitespace-nowrap"
                        className="flex-[2_1_0]"
                        details={{
                            'Ccy 2 Enabled': {
                                value: xplorSpotConfig.ccy2_enabled ? 'Yes' : 'No',
                                hidden: ['Index', 'Commodity'].includes(xplorSpotConfig.type)
                            },
                            'Min Order Size': {
                                value: `${ccy1Config.show} ${ccy1Config.min_order_size}`,
                                hidden: !!xplorSpotConfig.ccy2_enabled
                            },
                            'Min Order Size (Ccy1)': {
                                value: `${ccy1Config.show} ${ccy1Config.min_order_size}`,
                                hidden: !xplorSpotConfig.ccy2_enabled
                            },
                            'Min Order Size (Ccy2)': {
                                value: `${ccy2Config.show} ${ccy2Config.min_order_size}`,
                                hidden: !xplorSpotConfig.ccy2_enabled
                            }
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default InstrumentInfoContext;
