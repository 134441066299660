/* eslint-disable */
import {
    MarketDataFullSnapshotDownstreamEvent,
    MarketDataPriceTickerDownstreamEvent,
    MarketDataRequestRejectDownstreamEvent
} from '../price/DownstreamPriceProto';
import { Ping } from '../../../baseserver/communication/protobuf/NettyCommunication';
import { CallContext, CallOptions } from 'nice-grpc-common';
import {
    QuoteDownstreamEvent,
    QuoteRequestRejectDownstreamEvent,
    QuoteCancelDownstreamEvent
} from '../quote/DownstreamQuoteProto';
import { TradingSessionStatusDownstreamEvent } from '../status/DownstreamStatusProto';
import { MarketDataSubscriptionRequest } from '../price/UpstreamPriceProto';
import { Empty } from '../../../../../google/protobuf/empty';
import { QuoteRequest, QuoteCancelRequest } from '../quote/UpstreamQuoteProto';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.marketdata.api.notification';

/** Generic wrapper of the price events. This will allow for sub types to be sent in a single wrapper object. */
export interface MarketDataPriceEvent {
    marketDataFormattedPriceEvent?: MarketDataFormattedPriceEvent | undefined;
    marketDataPriceTickerDownstreamEvent?: MarketDataPriceTickerDownstreamEvent | undefined;
    marketDataRequestRejectDownstreamEvent?: MarketDataRequestRejectDownstreamEvent | undefined;
    fullSnapshot?: MarketDataFullSnapshotDownstreamEvent;
    isHeartBeat: boolean;
    ping?: Ping;
}

/** Generic wrapper of the price events. This will allow for sub types to be sent in a single wrapper object. */
export interface MarketDataQuoteEvent {
    quoteDownstreamEvent?: QuoteDownstreamEvent | undefined;
    quoteRequestRejectDownstreamEvent?: QuoteRequestRejectDownstreamEvent | undefined;
    quoteCancelDownstreamEvent?: QuoteCancelDownstreamEvent | undefined;
    isHeartBeat: boolean;
}

export interface TradingSessionEvent {
    tradingSessionStatusDownstreamEvent?: TradingSessionStatusDownstreamEvent | undefined;
}

export interface MarketDataSubscriptionCollection {
    subscriptionRequestCollection: MarketDataSubscriptionRequest[];
}

export interface MarketDataPriceEventCollection {
    priceEvent: MarketDataPriceEvent[];
}

export interface MarketDataFormattedPriceEvent {
    formattedPrices: { [key: string]: MarketDataFormattedPriceEvent_MarketDataEntryTypeItem };
}

export interface MarketDataFormattedPriceEvent_DisplayFigures {
    bigFigure: string;
    smallFigure: string;
    pointFigure: string;
}

export interface MarketDataFormattedPriceEvent_EntryType {
    displayFigures?: MarketDataFormattedPriceEvent_DisplayFigures;
    price: number;
    entrySize: number;
}

export interface MarketDataFormattedPriceEvent_MarketDataEntryTypeItem {
    values: { [key: string]: MarketDataFormattedPriceEvent_EntryType };
}

export interface MarketDataFormattedPriceEvent_MarketDataEntryTypeItem_ValuesEntry {
    key: string;
    value?: MarketDataFormattedPriceEvent_EntryType;
}

export interface MarketDataFormattedPriceEvent_FormattedPricesEntry {
    key: string;
    value?: MarketDataFormattedPriceEvent_MarketDataEntryTypeItem;
}

function createBaseMarketDataPriceEvent(): MarketDataPriceEvent {
    return {
        marketDataFormattedPriceEvent: undefined,
        marketDataPriceTickerDownstreamEvent: undefined,
        marketDataRequestRejectDownstreamEvent: undefined,
        fullSnapshot: undefined,
        isHeartBeat: false,
        ping: undefined
    };
}

export const MarketDataPriceEvent = {
    encode(message: MarketDataPriceEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.marketDataFormattedPriceEvent !== undefined) {
            MarketDataFormattedPriceEvent.encode(
                message.marketDataFormattedPriceEvent,
                writer.uint32(10).fork()
            ).ldelim();
        }
        if (message.marketDataPriceTickerDownstreamEvent !== undefined) {
            MarketDataPriceTickerDownstreamEvent.encode(
                message.marketDataPriceTickerDownstreamEvent,
                writer.uint32(18).fork()
            ).ldelim();
        }
        if (message.marketDataRequestRejectDownstreamEvent !== undefined) {
            MarketDataRequestRejectDownstreamEvent.encode(
                message.marketDataRequestRejectDownstreamEvent,
                writer.uint32(26).fork()
            ).ldelim();
        }
        if (message.fullSnapshot !== undefined) {
            MarketDataFullSnapshotDownstreamEvent.encode(message.fullSnapshot, writer.uint32(34).fork()).ldelim();
        }
        if (message.isHeartBeat === true) {
            writer.uint32(40).bool(message.isHeartBeat);
        }
        if (message.ping !== undefined) {
            Ping.encode(message.ping, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataPriceEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataPriceEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketDataFormattedPriceEvent = MarketDataFormattedPriceEvent.decode(
                        reader,
                        reader.uint32()
                    );
                    break;
                case 2:
                    message.marketDataPriceTickerDownstreamEvent = MarketDataPriceTickerDownstreamEvent.decode(
                        reader,
                        reader.uint32()
                    );
                    break;
                case 3:
                    message.marketDataRequestRejectDownstreamEvent = MarketDataRequestRejectDownstreamEvent.decode(
                        reader,
                        reader.uint32()
                    );
                    break;
                case 4:
                    message.fullSnapshot = MarketDataFullSnapshotDownstreamEvent.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.isHeartBeat = reader.bool();
                    break;
                case 6:
                    message.ping = Ping.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarketDataPriceEvent>): MarketDataPriceEvent {
        const message = createBaseMarketDataPriceEvent();
        message.marketDataFormattedPriceEvent =
            object.marketDataFormattedPriceEvent !== undefined && object.marketDataFormattedPriceEvent !== null
                ? MarketDataFormattedPriceEvent.fromPartial(object.marketDataFormattedPriceEvent)
                : undefined;
        message.marketDataPriceTickerDownstreamEvent =
            object.marketDataPriceTickerDownstreamEvent !== undefined &&
            object.marketDataPriceTickerDownstreamEvent !== null
                ? MarketDataPriceTickerDownstreamEvent.fromPartial(object.marketDataPriceTickerDownstreamEvent)
                : undefined;
        message.marketDataRequestRejectDownstreamEvent =
            object.marketDataRequestRejectDownstreamEvent !== undefined &&
            object.marketDataRequestRejectDownstreamEvent !== null
                ? MarketDataRequestRejectDownstreamEvent.fromPartial(object.marketDataRequestRejectDownstreamEvent)
                : undefined;
        message.fullSnapshot =
            object.fullSnapshot !== undefined && object.fullSnapshot !== null
                ? MarketDataFullSnapshotDownstreamEvent.fromPartial(object.fullSnapshot)
                : undefined;
        message.isHeartBeat = object.isHeartBeat ?? false;
        message.ping = object.ping !== undefined && object.ping !== null ? Ping.fromPartial(object.ping) : undefined;
        return message;
    }
};

function createBaseMarketDataQuoteEvent(): MarketDataQuoteEvent {
    return {
        quoteDownstreamEvent: undefined,
        quoteRequestRejectDownstreamEvent: undefined,
        quoteCancelDownstreamEvent: undefined,
        isHeartBeat: false
    };
}

export const MarketDataQuoteEvent = {
    encode(message: MarketDataQuoteEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.quoteDownstreamEvent !== undefined) {
            QuoteDownstreamEvent.encode(message.quoteDownstreamEvent, writer.uint32(10).fork()).ldelim();
        }
        if (message.quoteRequestRejectDownstreamEvent !== undefined) {
            QuoteRequestRejectDownstreamEvent.encode(
                message.quoteRequestRejectDownstreamEvent,
                writer.uint32(18).fork()
            ).ldelim();
        }
        if (message.quoteCancelDownstreamEvent !== undefined) {
            QuoteCancelDownstreamEvent.encode(message.quoteCancelDownstreamEvent, writer.uint32(26).fork()).ldelim();
        }
        if (message.isHeartBeat === true) {
            writer.uint32(40).bool(message.isHeartBeat);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataQuoteEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataQuoteEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.quoteDownstreamEvent = QuoteDownstreamEvent.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.quoteRequestRejectDownstreamEvent = QuoteRequestRejectDownstreamEvent.decode(
                        reader,
                        reader.uint32()
                    );
                    break;
                case 3:
                    message.quoteCancelDownstreamEvent = QuoteCancelDownstreamEvent.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.isHeartBeat = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarketDataQuoteEvent>): MarketDataQuoteEvent {
        const message = createBaseMarketDataQuoteEvent();
        message.quoteDownstreamEvent =
            object.quoteDownstreamEvent !== undefined && object.quoteDownstreamEvent !== null
                ? QuoteDownstreamEvent.fromPartial(object.quoteDownstreamEvent)
                : undefined;
        message.quoteRequestRejectDownstreamEvent =
            object.quoteRequestRejectDownstreamEvent !== undefined && object.quoteRequestRejectDownstreamEvent !== null
                ? QuoteRequestRejectDownstreamEvent.fromPartial(object.quoteRequestRejectDownstreamEvent)
                : undefined;
        message.quoteCancelDownstreamEvent =
            object.quoteCancelDownstreamEvent !== undefined && object.quoteCancelDownstreamEvent !== null
                ? QuoteCancelDownstreamEvent.fromPartial(object.quoteCancelDownstreamEvent)
                : undefined;
        message.isHeartBeat = object.isHeartBeat ?? false;
        return message;
    }
};

function createBaseTradingSessionEvent(): TradingSessionEvent {
    return { tradingSessionStatusDownstreamEvent: undefined };
}

export const TradingSessionEvent = {
    encode(message: TradingSessionEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.tradingSessionStatusDownstreamEvent !== undefined) {
            TradingSessionStatusDownstreamEvent.encode(
                message.tradingSessionStatusDownstreamEvent,
                writer.uint32(10).fork()
            ).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): TradingSessionEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradingSessionEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tradingSessionStatusDownstreamEvent = TradingSessionStatusDownstreamEvent.decode(
                        reader,
                        reader.uint32()
                    );
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<TradingSessionEvent>): TradingSessionEvent {
        const message = createBaseTradingSessionEvent();
        message.tradingSessionStatusDownstreamEvent =
            object.tradingSessionStatusDownstreamEvent !== undefined &&
            object.tradingSessionStatusDownstreamEvent !== null
                ? TradingSessionStatusDownstreamEvent.fromPartial(object.tradingSessionStatusDownstreamEvent)
                : undefined;
        return message;
    }
};

function createBaseMarketDataSubscriptionCollection(): MarketDataSubscriptionCollection {
    return { subscriptionRequestCollection: [] };
}

export const MarketDataSubscriptionCollection = {
    encode(message: MarketDataSubscriptionCollection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.subscriptionRequestCollection) {
            MarketDataSubscriptionRequest.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataSubscriptionCollection {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataSubscriptionCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.subscriptionRequestCollection.push(
                        MarketDataSubscriptionRequest.decode(reader, reader.uint32())
                    );
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarketDataSubscriptionCollection>): MarketDataSubscriptionCollection {
        const message = createBaseMarketDataSubscriptionCollection();
        message.subscriptionRequestCollection =
            object.subscriptionRequestCollection?.map((e) => MarketDataSubscriptionRequest.fromPartial(e)) || [];
        return message;
    }
};

function createBaseMarketDataPriceEventCollection(): MarketDataPriceEventCollection {
    return { priceEvent: [] };
}

export const MarketDataPriceEventCollection = {
    encode(message: MarketDataPriceEventCollection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.priceEvent) {
            MarketDataPriceEvent.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataPriceEventCollection {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataPriceEventCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.priceEvent.push(MarketDataPriceEvent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarketDataPriceEventCollection>): MarketDataPriceEventCollection {
        const message = createBaseMarketDataPriceEventCollection();
        message.priceEvent = object.priceEvent?.map((e) => MarketDataPriceEvent.fromPartial(e)) || [];
        return message;
    }
};

function createBaseMarketDataFormattedPriceEvent(): MarketDataFormattedPriceEvent {
    return { formattedPrices: {} };
}

export const MarketDataFormattedPriceEvent = {
    encode(message: MarketDataFormattedPriceEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        Object.entries(message.formattedPrices).forEach(([key, value]) => {
            MarketDataFormattedPriceEvent_FormattedPricesEntry.encode(
                { key: key as any, value },
                writer.uint32(10).fork()
            ).ldelim();
        });
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataFormattedPriceEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataFormattedPriceEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    const entry1 = MarketDataFormattedPriceEvent_FormattedPricesEntry.decode(reader, reader.uint32());
                    if (entry1.value !== undefined) {
                        message.formattedPrices[entry1.key] = entry1.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarketDataFormattedPriceEvent>): MarketDataFormattedPriceEvent {
        const message = createBaseMarketDataFormattedPriceEvent();
        message.formattedPrices = Object.entries(object.formattedPrices ?? {}).reduce<{
            [key: string]: MarketDataFormattedPriceEvent_MarketDataEntryTypeItem;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = MarketDataFormattedPriceEvent_MarketDataEntryTypeItem.fromPartial(value);
            }
            return acc;
        }, {});
        return message;
    }
};

function createBaseMarketDataFormattedPriceEvent_DisplayFigures(): MarketDataFormattedPriceEvent_DisplayFigures {
    return { bigFigure: '', smallFigure: '', pointFigure: '' };
}

export const MarketDataFormattedPriceEvent_DisplayFigures = {
    encode(
        message: MarketDataFormattedPriceEvent_DisplayFigures,
        writer: _m0.Writer = _m0.Writer.create()
    ): _m0.Writer {
        if (message.bigFigure !== '') {
            writer.uint32(10).string(message.bigFigure);
        }
        if (message.smallFigure !== '') {
            writer.uint32(18).string(message.smallFigure);
        }
        if (message.pointFigure !== '') {
            writer.uint32(26).string(message.pointFigure);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataFormattedPriceEvent_DisplayFigures {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataFormattedPriceEvent_DisplayFigures();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.bigFigure = reader.string();
                    break;
                case 2:
                    message.smallFigure = reader.string();
                    break;
                case 3:
                    message.pointFigure = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<MarketDataFormattedPriceEvent_DisplayFigures>
    ): MarketDataFormattedPriceEvent_DisplayFigures {
        const message = createBaseMarketDataFormattedPriceEvent_DisplayFigures();
        message.bigFigure = object.bigFigure ?? '';
        message.smallFigure = object.smallFigure ?? '';
        message.pointFigure = object.pointFigure ?? '';
        return message;
    }
};

function createBaseMarketDataFormattedPriceEvent_EntryType(): MarketDataFormattedPriceEvent_EntryType {
    return { displayFigures: undefined, price: 0, entrySize: 0 };
}

export const MarketDataFormattedPriceEvent_EntryType = {
    encode(message: MarketDataFormattedPriceEvent_EntryType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.displayFigures !== undefined) {
            MarketDataFormattedPriceEvent_DisplayFigures.encode(
                message.displayFigures,
                writer.uint32(10).fork()
            ).ldelim();
        }
        if (message.price !== 0) {
            writer.uint32(17).double(message.price);
        }
        if (message.entrySize !== 0) {
            writer.uint32(25).double(message.entrySize);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataFormattedPriceEvent_EntryType {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataFormattedPriceEvent_EntryType();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.displayFigures = MarketDataFormattedPriceEvent_DisplayFigures.decode(
                        reader,
                        reader.uint32()
                    );
                    break;
                case 2:
                    message.price = reader.double();
                    break;
                case 3:
                    message.entrySize = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarketDataFormattedPriceEvent_EntryType>): MarketDataFormattedPriceEvent_EntryType {
        const message = createBaseMarketDataFormattedPriceEvent_EntryType();
        message.displayFigures =
            object.displayFigures !== undefined && object.displayFigures !== null
                ? MarketDataFormattedPriceEvent_DisplayFigures.fromPartial(object.displayFigures)
                : undefined;
        message.price = object.price ?? 0;
        message.entrySize = object.entrySize ?? 0;
        return message;
    }
};

function createBaseMarketDataFormattedPriceEvent_MarketDataEntryTypeItem(): MarketDataFormattedPriceEvent_MarketDataEntryTypeItem {
    return { values: {} };
}

export const MarketDataFormattedPriceEvent_MarketDataEntryTypeItem = {
    encode(
        message: MarketDataFormattedPriceEvent_MarketDataEntryTypeItem,
        writer: _m0.Writer = _m0.Writer.create()
    ): _m0.Writer {
        Object.entries(message.values).forEach(([key, value]) => {
            MarketDataFormattedPriceEvent_MarketDataEntryTypeItem_ValuesEntry.encode(
                { key: key as any, value },
                writer.uint32(10).fork()
            ).ldelim();
        });
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataFormattedPriceEvent_MarketDataEntryTypeItem {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataFormattedPriceEvent_MarketDataEntryTypeItem();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    const entry1 = MarketDataFormattedPriceEvent_MarketDataEntryTypeItem_ValuesEntry.decode(
                        reader,
                        reader.uint32()
                    );
                    if (entry1.value !== undefined) {
                        message.values[entry1.key] = entry1.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<MarketDataFormattedPriceEvent_MarketDataEntryTypeItem>
    ): MarketDataFormattedPriceEvent_MarketDataEntryTypeItem {
        const message = createBaseMarketDataFormattedPriceEvent_MarketDataEntryTypeItem();
        message.values = Object.entries(object.values ?? {}).reduce<{
            [key: string]: MarketDataFormattedPriceEvent_EntryType;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = MarketDataFormattedPriceEvent_EntryType.fromPartial(value);
            }
            return acc;
        }, {});
        return message;
    }
};

function createBaseMarketDataFormattedPriceEvent_MarketDataEntryTypeItem_ValuesEntry(): MarketDataFormattedPriceEvent_MarketDataEntryTypeItem_ValuesEntry {
    return { key: '', value: undefined };
}

export const MarketDataFormattedPriceEvent_MarketDataEntryTypeItem_ValuesEntry = {
    encode(
        message: MarketDataFormattedPriceEvent_MarketDataEntryTypeItem_ValuesEntry,
        writer: _m0.Writer = _m0.Writer.create()
    ): _m0.Writer {
        if (message.key !== '') {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== undefined) {
            MarketDataFormattedPriceEvent_EntryType.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },

    decode(
        input: _m0.Reader | Uint8Array,
        length?: number
    ): MarketDataFormattedPriceEvent_MarketDataEntryTypeItem_ValuesEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataFormattedPriceEvent_MarketDataEntryTypeItem_ValuesEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = MarketDataFormattedPriceEvent_EntryType.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<MarketDataFormattedPriceEvent_MarketDataEntryTypeItem_ValuesEntry>
    ): MarketDataFormattedPriceEvent_MarketDataEntryTypeItem_ValuesEntry {
        const message = createBaseMarketDataFormattedPriceEvent_MarketDataEntryTypeItem_ValuesEntry();
        message.key = object.key ?? '';
        message.value =
            object.value !== undefined && object.value !== null
                ? MarketDataFormattedPriceEvent_EntryType.fromPartial(object.value)
                : undefined;
        return message;
    }
};

function createBaseMarketDataFormattedPriceEvent_FormattedPricesEntry(): MarketDataFormattedPriceEvent_FormattedPricesEntry {
    return { key: '', value: undefined };
}

export const MarketDataFormattedPriceEvent_FormattedPricesEntry = {
    encode(
        message: MarketDataFormattedPriceEvent_FormattedPricesEntry,
        writer: _m0.Writer = _m0.Writer.create()
    ): _m0.Writer {
        if (message.key !== '') {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== undefined) {
            MarketDataFormattedPriceEvent_MarketDataEntryTypeItem.encode(
                message.value,
                writer.uint32(18).fork()
            ).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataFormattedPriceEvent_FormattedPricesEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataFormattedPriceEvent_FormattedPricesEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = MarketDataFormattedPriceEvent_MarketDataEntryTypeItem.decode(
                        reader,
                        reader.uint32()
                    );
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<MarketDataFormattedPriceEvent_FormattedPricesEntry>
    ): MarketDataFormattedPriceEvent_FormattedPricesEntry {
        const message = createBaseMarketDataFormattedPriceEvent_FormattedPricesEntry();
        message.key = object.key ?? '';
        message.value =
            object.value !== undefined && object.value !== null
                ? MarketDataFormattedPriceEvent_MarketDataEntryTypeItem.fromPartial(object.value)
                : undefined;
        return message;
    }
};

export type MarketDataServiceDefinition = typeof MarketDataServiceDefinition;
export const MarketDataServiceDefinition = {
    name: 'MarketDataService',
    fullName: 'com.celertech.marketdata.api.notification.MarketDataService',
    methods: {
        createPriceSubscriptionRequest: {
            name: 'createPriceSubscriptionRequest',
            requestType: MarketDataSubscriptionRequest,
            requestStream: false,
            responseType: MarketDataPriceEvent,
            responseStream: false,
            options: {}
        },
        createPriceSubscriptionRequestBatch: {
            name: 'createPriceSubscriptionRequestBatch',
            requestType: MarketDataSubscriptionCollection,
            requestStream: false,
            responseType: Empty,
            responseStream: false,
            options: {}
        },
        createQuoteRequest: {
            name: 'createQuoteRequest',
            requestType: QuoteRequest,
            requestStream: false,
            responseType: MarketDataQuoteEvent,
            responseStream: false,
            options: {}
        },
        createQuoteCancelRequest: {
            name: 'createQuoteCancelRequest',
            requestType: QuoteCancelRequest,
            requestStream: false,
            responseType: MarketDataQuoteEvent,
            responseStream: false,
            options: {}
        },
        /** Used to instantiate market data price listeners that will stream pricing over websockets */
        subscribeToPrices: {
            name: 'subscribeToPrices',
            requestType: Empty,
            requestStream: false,
            responseType: Empty,
            responseStream: false,
            options: {}
        },
        subscribeToQuotes: {
            name: 'subscribeToQuotes',
            requestType: Empty,
            requestStream: false,
            responseType: MarketDataQuoteEvent,
            responseStream: true,
            options: {}
        },
        subscribeToTradingStatus: {
            name: 'subscribeToTradingStatus',
            requestType: Empty,
            requestStream: false,
            responseType: TradingSessionEvent,
            responseStream: true,
            options: {}
        }
    }
} as const;

export interface MarketDataServiceServiceImplementation<CallContextExt = {}> {
    createPriceSubscriptionRequest(
        request: MarketDataSubscriptionRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<MarketDataPriceEvent>>;
    createPriceSubscriptionRequestBatch(
        request: MarketDataSubscriptionCollection,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<Empty>>;
    createQuoteRequest(
        request: QuoteRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<MarketDataQuoteEvent>>;
    createQuoteCancelRequest(
        request: QuoteCancelRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<MarketDataQuoteEvent>>;
    /** Used to instantiate market data price listeners that will stream pricing over websockets */
    subscribeToPrices(request: Empty, context: CallContext & CallContextExt): Promise<DeepPartial<Empty>>;
    subscribeToQuotes(
        request: Empty,
        context: CallContext & CallContextExt
    ): ServerStreamingMethodResult<DeepPartial<MarketDataQuoteEvent>>;
    subscribeToTradingStatus(
        request: Empty,
        context: CallContext & CallContextExt
    ): ServerStreamingMethodResult<DeepPartial<TradingSessionEvent>>;
}

export interface MarketDataServiceClient<CallOptionsExt = {}> {
    createPriceSubscriptionRequest(
        request: DeepPartial<MarketDataSubscriptionRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<MarketDataPriceEvent>;
    createPriceSubscriptionRequestBatch(
        request: DeepPartial<MarketDataSubscriptionCollection>,
        options?: CallOptions & CallOptionsExt
    ): Promise<Empty>;
    createQuoteRequest(
        request: DeepPartial<QuoteRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<MarketDataQuoteEvent>;
    createQuoteCancelRequest(
        request: DeepPartial<QuoteCancelRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<MarketDataQuoteEvent>;
    /** Used to instantiate market data price listeners that will stream pricing over websockets */
    subscribeToPrices(request: DeepPartial<Empty>, options?: CallOptions & CallOptionsExt): Promise<Empty>;
    subscribeToQuotes(
        request: DeepPartial<Empty>,
        options?: CallOptions & CallOptionsExt
    ): AsyncIterable<MarketDataQuoteEvent>;
    subscribeToTradingStatus(
        request: DeepPartial<Empty>,
        options?: CallOptions & CallOptionsExt
    ): AsyncIterable<TradingSessionEvent>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

export type ServerStreamingMethodResult<Response> = {
    [Symbol.asyncIterator](): AsyncIterator<Response, void>;
};
