import { accumulatePriceBookBidAsks, calculateVWAP } from '@/helpers/orderbookHelper';
import { useAppSelector } from '@/state/hooks';
import { TraderMarket } from '@/state/reducers/traderMarketSlice';
import { RootState } from '@/state/store';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { GetColorIndexesReturn, GetPipIndexesReturn, useInstrument } from '@/utils/hooks/useInstrument';
import usePreviousSelector from '@/utils/hooks/usePreviousSelector';
import BigNumber from 'bignumber.js';
import { useMemo, useState } from 'react';
import usePreviousState from './usePreviousState';

interface PreviousBidAsk {
    bid: number;
    ask: number;
}

export interface UseTickerOrderBookReturn {
    bestBid: {
        price: number;
        pricePips?: GetPipIndexesReturn;
        highlights?: GetColorIndexesReturn;
    };
    bestAsk: {
        price: number;
        pricePips?: GetPipIndexesReturn;
        highlights?: GetColorIndexesReturn;
    };
    bidVWAP: {
        price: number;
        pricePips?: GetPipIndexesReturn;
        highlights?: GetColorIndexesReturn;
    };
    askVWAP: {
        price: number;
        pricePips?: GetPipIndexesReturn;
        highlights?: GetColorIndexesReturn;
    };
    spread?: string;
    vwapSpread?: string;
}

const defaultBidAsk = { bid: 0, ask: 0 };
const defaultVWAP = { vwap: null, message: null };

const useTickerOrderBook = (market: TraderMarket, quantity: number): UseTickerOrderBookReturn => {
    const selectBidAsk = (state: RootState) => state.marketPair.bidAsk[market.celer] || defaultBidAsk;

    const best = useAppSelector(selectBidAsk);
    const prevBest = usePreviousSelector(selectBidAsk) as PreviousBidAsk;
    const priceBooks = useAppSelector((state) => state.celerMarket.priceBooks[market.celer]);
    const { bids, asks } = useMemo(() => accumulatePriceBookBidAsks(priceBooks), [priceBooks, market.celer]);

    const [askHighlights, setAskHighlights] = useState<GetColorIndexesReturn>();
    const [bidHighlights, setBidHighlights] = useState<GetColorIndexesReturn>();
    const [askVWAPHighlights, setAskVWAPHighlights] = useState<GetColorIndexesReturn>();
    const [bidVWAPHighlights, setBidVWAPHighlights] = useState<GetColorIndexesReturn>();

    const { formatPip, formatHighlights, formatSpread } = useInstrument(market.celer);

    // currently displaying bids and asks
    const spread = useMemo(() => {
        if (best.bid && prevBest.bid) return formatSpread(BigNumber(best.ask).minus(best.bid));
        else return undefined;
    }, [best.bid, best.ask]);

    const vwap = useMemo(() => {
        if (quantity) {
            const reversedAsks = asks ? [...asks].reverse() : [];
            const ask = calculateVWAP(reversedAsks, quantity);
            const bid = calculateVWAP(bids, quantity);

            return { bid: bid.vwap?.toNumber() || 0, ask: ask.vwap?.toNumber() || 0 };
        } else {
            return { bid: 0, ask: 0 };
        }
    }, [bids, asks, quantity]);

    const vwapSpread = useMemo(() => {
        if (vwap.bid && vwap.ask) return formatSpread(BigNumber(vwap.ask).minus(vwap.bid));
        else return undefined;
    }, [vwap.bid, vwap.ask]);

    const bestBid = useMemo(
        () => ({
            price: best.bid,
            pricePips: best.bid ? formatPip(best.bid) : undefined,
            highlights: bidHighlights
        }),
        [best.bid]
    );

    const bestAsk = useMemo(
        () => ({
            price: best.ask,
            pricePips: best.ask ? formatPip(best.ask) : undefined,
            highlights: askHighlights
        }),
        [best.ask]
    );

    const bidVWAP = useMemo(
        () => ({
            price: vwap.bid,
            pricePips: vwap.bid ? formatPip(vwap.bid) : undefined,
            highlights: bidVWAPHighlights
        }),
        [vwap.bid]
    );

    const askVWAP = useMemo(
        () => ({
            price: vwap.ask,
            pricePips: vwap.ask ? formatPip(vwap.ask) : undefined,
            highlights: askVWAPHighlights
        }),
        [vwap.ask]
    );

    const prevVWAP = usePreviousState(vwap) as { bid: number; ask: number };

    // for highlighting changes based on prev price
    useDidUpdate(() => {
        if (best.bid && prevBest.bid) setBidHighlights(formatHighlights(prevBest.bid, best.bid, bidHighlights));
    }, [best.bid]);

    useDidUpdate(() => {
        if (best.ask && prevBest.ask) setAskHighlights(formatHighlights(prevBest.ask, best.ask, askHighlights));
    }, [best.ask]);

    useDidUpdate(() => {
        if (vwap.bid && prevVWAP.bid) setBidVWAPHighlights(formatHighlights(prevVWAP.bid, vwap.bid, bidVWAPHighlights));
    }, [vwap.bid]);

    useDidUpdate(() => {
        if (vwap.ask && prevVWAP.ask) setAskVWAPHighlights(formatHighlights(prevVWAP.ask, vwap.ask, askVWAPHighlights));
    }, [vwap.ask]);

    return {
        bestBid,
        bestAsk,
        bidVWAP,
        askVWAP,
        spread,
        vwapSpread
    };
};

export default useTickerOrderBook;
