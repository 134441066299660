import { Button } from '@/components/common/Button';
import Divider from '@/components/common/Divider';
import Drawer from '@/components/common/Drawer';
import Entry from '@/components/common/Entry';
import {
    WithdrawalRequestFormInput,
    WithdrawalRequestFormValues,
    withdrawalRequestSchema
} from '@/components/form/schema/withdrawalRequestSchema';
import ErrorMessage from '@/components/inputs/ErrorMessage';
import FilterSelect from '@/components/inputs/FilterSelect';
import InputController from '@/components/inputs/InputController';
import NumberInput from '@/components/inputs/NumberInput';
import Select from '@/components/inputs/Select';
import TextArea from '@/components/inputs/TextArea';
import { convertedQtyFormatterConfig } from '@/config/config';
import { portalAPI } from '@/helpers/environmentHelper';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials } from '@/state/reducers/authSlice';
import { addSpacingToCharacter } from '@/utils/format';
import useToast from '@/utils/hooks/useToast';
import useXplorPortal from '@/utils/hooks/useXplorPortal';
import { useSso } from '@/utils/providers/SSOProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useNumberFormatter } from 'react-aria';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTitle } from '../Modal';

type Option = {
    label: string;
    value: string;
};

export enum WithdrawalType {
    FIAT = 'FIAT',
    CRYPTO = 'CRYPTO'
}

export const withdrawalTypeOptions: Option[] = [
    {
        label: WithdrawalType.FIAT,
        value: WithdrawalType.FIAT
    },
    {
        label: WithdrawalType.CRYPTO,
        value: WithdrawalType.CRYPTO
    }
];

const defaultBankInfoId = { label: 'Please select a bank account', value: undefined };
const defaultWalletId = { label: 'Please select a wallet', value: undefined };
const defaultCurrencyId = { label: 'Please select a currency', value: undefined };
const defaultAssetId = { label: 'Please select a asset', value: undefined };
const defaultWithdrawalType = withdrawalTypeOptions[0];

const defaultValues: Partial<WithdrawalRequestFormInput> = {
    amount: undefined,
    withdrawalType: defaultWithdrawalType,
    currencyId: defaultCurrencyId,
    bankInfoId: defaultBankInfoId,
    walletId: defaultWalletId,
    requestDescription: ''
};

interface WithdrawalRequestModalProps extends ModalProps {}

const WithdrawalRequestModal = (props: WithdrawalRequestModalProps) => {
    const { opened, handlers } = props;

    const { jwt, foundEntity, balancesApi, bankInfoApi, currenciesApi, walletInfoApi } = useSso();
    const convertedQtyFormatter = useNumberFormatter(convertedQtyFormatterConfig);

    const [error, setError] = useState<any>({ message: '' });
    const [showBalanceAmount, setShowBalanceAmount] = useState(false);
    const [availableBalance, setAvailableBalance] = useState(0);

    const [toastError, toastSuccess] = useToast();

    const formInstance = useForm<WithdrawalRequestFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(withdrawalRequestSchema(availableBalance, foundEntity))
    });

    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = formInstance;

    const [selectedBankInfo, selectedWithdrawalType, selectedCurrency, selectedWalletId] = watch([
        'bankInfoId',
        'withdrawalType',
        'currencyId',
        'walletId'
    ]);

    const { data: currencies, isLoading: isCurrenciesLoading } = currenciesApi;
    const { data: balances, isLoading: isBalancesLoading, mutate: refreshBalances } = balancesApi;
    const { data: bankInfo, isLoading: isBankInfoLoading } = bankInfoApi;
    const { data: wallets, isLoading: isWalletLoading } = walletInfoApi;

    const currencyOptions = useMemo(() => {
        return currencies
            ? currencies.data
                  .filter(
                      (currency: any) =>
                          currency.enabledForWithdrawal && currency.type === selectedWithdrawalType?.value
                  )
                  .map((currency: any) => {
                      return {
                          label: currency.digitalAssetName || currency.name,
                          value: currency.id
                      };
                  })
            : [];
    }, [currencies, selectedWithdrawalType]);

    const bankInfoOptions = useMemo(() => {
        return bankInfo
            ? bankInfo.data
                  ?.filter((bankInfo) => bankInfo.active && bankInfo.baseCurrencyId === selectedCurrency?.value)
                  ?.map((bankInfo) => ({
                      label: bankInfo.friendlyName,
                      value: bankInfo.bankInfoId
                  }))
            : [];
    }, [bankInfo, selectedCurrency]);

    const accountDetails = useMemo(() => {
        const selectedBankInfoDetails = bankInfo?.data?.find((info) => info.bankInfoId === selectedBankInfo?.value);
        return {
            bankName: selectedBankInfoDetails?.bankName,
            accountNumber: selectedBankInfoDetails?.accountNumber,
            ibanCode: selectedBankInfoDetails?.ibanCode,
            baseCurrency: {
                label: selectedBankInfoDetails?.baseCurrencyName,
                value: selectedBankInfoDetails?.baseCurrencyId
            }
        };
    }, [bankInfo, selectedBankInfo]);

    const walletDetails = useMemo(() => {
        const selectedWalletDetails = wallets?.data?.find((wallet) => wallet.walletId === selectedWalletId?.value);
        return {
            network: {
                label: selectedWalletDetails?.networkName,
                value: selectedWalletDetails?.networkId
            },
            assets: selectedWalletDetails?.supportedAssets
                .map((asset) => asset.digitalAssetName || asset.currencyName)
                .join(', ')
        };
    }, [wallets, selectedWalletId]);

    const walletOptions = useMemo(() => {
        return wallets
            ? wallets.data
                  ?.filter((wallet) =>
                      wallet.supportedAssets.find((asset) => asset.currencyId === selectedCurrency?.value)
                  )
                  ?.map((wallet) => ({
                      label: wallet.friendlyName,
                      value: wallet.walletId
                  }))
            : [];
    }, [wallets, selectedCurrency]);

    const hasAccount = useMemo(() => {
        return selectedCurrency?.value && (bankInfoOptions?.length || walletOptions?.length);
    }, [selectedCurrency, bankInfoOptions, walletOptions]);

    const currentBalance = useMemo(() => {
        return balances?.data?.find((balance: any) => balance.currencyName === selectedCurrency?.label)?.balance || 0;
    }, [balances, selectedCurrency]);

    const onSubmit = async (data: WithdrawalRequestFormInput) => {
        const dataValidated = data as WithdrawalRequestFormValues;

        try {
            if (dataValidated.withdrawalType?.value === WithdrawalType.FIAT) {
                const payload = {
                    entityId: foundEntity.entityId,
                    amount: dataValidated.amount || 0,
                    bankInfoId: dataValidated.bankInfoId.value,
                    requestDescription: dataValidated.requestDescription || null,
                    currencyId: dataValidated.currencyId.value
                };
                await axios.post(portalAPI('/api/requests/withdrawal/fiat'), payload, {
                    headers: { Authorization: `Bearer ${jwt}` }
                });
                const title = 'Fiat Withdrawal';
                const body = 'Withdrawal request submitted successfully';
                toastSuccess({ body, title });
            } else {
                const payload = {
                    entityId: foundEntity.entityId,
                    amount: dataValidated.amount || 0,
                    walletId: dataValidated.walletId.value,
                    requestDescription: dataValidated.requestDescription || null,
                    currencyId: dataValidated.currencyId.value
                };
                await axios.post(portalAPI('/api/requests/withdrawal/crypto'), payload, {
                    headers: { Authorization: `Bearer ${jwt}` }
                });
                const title = 'Crypto Withdrawal';
                const body = 'Withdrawal request submitted successfully';
                toastSuccess({ body, title });
            }
            handlers.close();
        } catch (err: any) {
            setError(err?.response?.data || err);
        }
    };

    const onError = (e: FieldErrors) => console.log(e);

    useEffect(() => {
        if (opened && walletOptions && walletOptions.length > 0 && walletOptions.length < 2) {
            setValue('walletId', walletOptions[0], { shouldValidate: true });
        }
    }, [walletOptions]);

    useEffect(() => {
        if (opened && bankInfoOptions && bankInfoOptions.length > 0 && bankInfoOptions.length < 2) {
            setValue('bankInfoId', bankInfoOptions[0], { shouldValidate: true });
        }
    }, [bankInfoOptions]);

    useEffect(() => {
        if (opened && selectedWithdrawalType?.value)
            reset({
                ...defaultValues,
                bankInfoId: selectedBankInfo?.value ? selectedBankInfo : defaultBankInfoId,
                withdrawalType: selectedWithdrawalType,
                currencyId: selectedWithdrawalType?.value === WithdrawalType.FIAT ? defaultCurrencyId : defaultAssetId
            });
    }, [selectedWithdrawalType?.value]);

    useEffect(() => {
        if (opened && selectedCurrency?.value) {
            refreshBalances();
            reset({
                ...defaultValues,
                bankInfoId: selectedBankInfo?.value ? selectedBankInfo : defaultBankInfoId,
                withdrawalType: selectedWithdrawalType,
                currencyId: selectedCurrency
            });
        }
    }, [selectedCurrency?.value]);

    useEffect(() => {
        if (opened && selectedCurrency?.value) {
            if (!hasAccount) {
                setShowBalanceAmount(false);
                if (selectedWithdrawalType?.value)
                    setError({
                        message: (
                            <WhitelistError
                                type={selectedWithdrawalType?.value as any}
                                selectedCurrency={selectedCurrency}
                            />
                        )
                    });
            } else {
                if (!foundEntity?.permissions?.includes('ACCOUNT_VIEW')) setShowBalanceAmount(false);
                else setShowBalanceAmount(true);
                setError({ message: '' });
            }
        }
    }, [bankInfoOptions, walletOptions, selectedCurrency, foundEntity, setValue]);

    useEffect(() => {
        setAvailableBalance(currentBalance);
    }, [currentBalance]);

    useEffect(() => {
        if (!opened) {
            reset(defaultValues, { keepIsValid: false });
            setError({ message: '' });
            setShowBalanceAmount(false);
        } else {
            refreshBalances();
        }
    }, [opened]);

    return (
        <Modal {...props} className="h-auto" size="max-w-2xl">
            <div className="h-full flex flex-col lg:block lg:h-auto">
                <ModalHeader>
                    <ModalTitle>Withdrawals</ModalTitle>
                    <ModalClose handlers={handlers} />
                </ModalHeader>
                <Divider />
                <FormProvider {...formInstance}>
                    <form
                        autoComplete="off"
                        className="h-full flex flex-col"
                        onSubmit={handleSubmit(onSubmit, onError)}>
                        <Divider />
                        <Drawer.Body>
                            <Drawer.SubBody>
                                <InputController
                                    name="withdrawalType"
                                    label="Withdrawal Type"
                                    placeholder="Withdrawal Type"
                                    required>
                                    <Select options={withdrawalTypeOptions} enableSorting />
                                </InputController>
                                <InputController
                                    name="currencyId"
                                    label={selectedWithdrawalType?.value === 'FIAT' ? 'Currency' : 'Asset'}
                                    asyncInput={!jwt || isCurrenciesLoading}
                                    placeholder="Currency"
                                    required>
                                    <FilterSelect options={currencyOptions} enableSorting />
                                </InputController>
                                <InputController
                                    name="amount"
                                    label="Amount"
                                    placeholder="Amount"
                                    inputInfo={
                                        showBalanceAmount ? (
                                            <i className="text-sm text-yellow-400">
                                                Current{' '}
                                                {selectedWithdrawalType?.value === 'FIAT' ? 'Account' : 'Wallet'}{' '}
                                                Balance {selectedCurrency?.value && selectedCurrency?.label}{' '}
                                                {convertedQtyFormatter.format(currentBalance)}
                                            </i>
                                        ) : undefined
                                    }
                                    required
                                    alignStart
                                    disabled={!selectedCurrency?.value || !hasAccount}>
                                    <NumberInput />
                                </InputController>
                                <InputController
                                    name="requestDescription"
                                    label="Request Description"
                                    placeholder="Request Description"
                                    alignStart>
                                    <TextArea rows={2} />
                                </InputController>
                            </Drawer.SubBody>

                            <Divider />
                            <Drawer.SubBody>
                                {selectedWithdrawalType?.value === WithdrawalType.FIAT ? (
                                    <BankAccountDetails
                                        accountDetails={accountDetails}
                                        bankInfoOptions={bankInfoOptions}
                                        isBankInfoLoading={!jwt || isBankInfoLoading}
                                    />
                                ) : (
                                    <WalletDetails
                                        walletDetails={walletDetails}
                                        walletOptions={walletOptions}
                                        isWalletLoading={!jwt || isWalletLoading}
                                    />
                                )}
                                <ErrorMessage error={error} />
                            </Drawer.SubBody>
                        </Drawer.Body>
                        <Divider />
                        <Drawer.Footer>
                            <button
                                type="button"
                                className="rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500"
                                onClick={() => handlers.close()}>
                                Cancel
                            </button>
                            <Button className="!w-auto" isLoading={isSubmitting} disabled={!isValid}>
                                Submit Request
                            </Button>
                        </Drawer.Footer>
                    </form>
                </FormProvider>
            </div>
        </Modal>
    );
};

export default WithdrawalRequestModal;

const RedirectToPortal = () => {
    const credentials = useAppSelector(selectCredentials);
    const { onXplorPortal } = useXplorPortal(credentials);
    return (
        <span>
            Click
            <button onClick={onXplorPortal} className="text-neutral-300 hover:underline mx-1">
                here
            </button>
            to access XplorPortal
        </span>
    );
};

interface WhitelistErrorProps {
    type: 'FIAT' | 'CRYPTO';
    selectedCurrency: any;
}

export const WhitelistError = (props: WhitelistErrorProps) => {
    const { type, selectedCurrency } = props;
    return (
        <div className="flex flex-col gap-3">
            <span>
                Please submit a Whitelist request for your {type === 'FIAT' ? 'bank account' : 'wallet address'} in{' '}
                {selectedCurrency?.label} at XplorPortal.
            </span>
            <RedirectToPortal />
        </div>
    );
};

const BankAccountDetails = ({ accountDetails, bankInfoOptions, isBankInfoLoading }: any) => {
    return (
        <>
            <InputController
                name="bankInfoId"
                label="Bank Account"
                placeholder="Bank Account"
                asyncInput={isBankInfoLoading}
                disabled={bankInfoOptions && bankInfoOptions.length < 2}
                required>
                <Select options={bankInfoOptions} enableSorting />
            </InputController>

            <Entry label="Bank Name" value={accountDetails?.bankName} disabled />
            <Entry
                label="Account Number / IBAN code"
                value={accountDetails?.accountNumber || addSpacingToCharacter(accountDetails?.ibanCode, 4)}
                disabled
            />
        </>
    );
};

const WalletDetails = ({ walletDetails, walletOptions, isWalletLoading }: any) => {
    return (
        <>
            <InputController
                name="walletId"
                label="Wallet"
                placeholder="Wallet"
                asyncInput={isWalletLoading}
                disabled={walletOptions && walletOptions.length < 2}
                required>
                <Select options={walletOptions} enableSorting />
            </InputController>

            <Entry label="Network" value={walletDetails.network.label} disabled />
        </>
    );
};
